const trapFocus = (root, closeCb = () => {}) => {
    const focusableElementsString = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]'

    const wasInFocus = document.activeElement
    const interactiveElements = Array.from(root.querySelectorAll(focusableElementsString))
    const interactiveElementsCount = interactiveElements.length
    const firstElement = interactiveElements[0]
    const lastElement = interactiveElements[interactiveElementsCount - 1]
    firstElement.focus()

    const keydownHandler = e => {
        const isTab = e.code === 'Tab' && !e.shiftKey
        const isShiftTab = e.code === 'Tab' && e.shiftKey
        const isEsc = e.code === 'Escape'
        const isFirstElementInFocus = document.activeElement === firstElement
        const isLastElementInFocus = document.activeElement === lastElement

        if (isFirstElementInFocus && isShiftTab) {
            e.preventDefault()
            lastElement.focus()
            return
        }

        if (isLastElementInFocus && isTab) {
            e.preventDefault()
            firstElement.focus()
            return
        }

        if (isEsc) {
            e.preventDefault()
            closeCb()
        }
    }

    document.addEventListener('keydown', keydownHandler)

    return () => {
        if (wasInFocus) wasInFocus.focus()
        document.removeEventListener('keydown', keydownHandler)
    }
}

const preventScroll = () => {
    const initialPosition = document.documentElement.style.position
    const initialTop = document.documentElement.style.top
    const initialScrollY = window.scrollY
    document.documentElement.style.top = `-${initialScrollY}px`;
    document.documentElement.style.position = 'fixed';

    return () => {
        document.documentElement.style.position = initialPosition;
        document.documentElement.style.top = initialTop;
        window.scrollTo(0, initialScrollY)
    }
}

const fastregPopup = {
    untrapFocus:    null,
    authPopup:      document.getElementById('auth-popup'),
    authPopupClose: document.querySelector('.auth-popup__close'),
    paranja:        document.querySelector('.auth-popup__backspace'),
    restoreScroll:  null,

    initPopup() {
        this.authPopupClose.addEventListener('click', this.hideFastregPopup.bind(this))
        this.paranja.addEventListener('click', this.hideFastregPopup.bind(this))
        this.authPopup.querySelector('[name="reg_url"]').value = location.host
    },

    hideFastregPopup() {
        this.authPopup.hidden = true
        if (this.restoreScroll) this.restoreScroll()
        if (this.untrapFocus) this.untrapFocus()
    },

    showFastregPopup() {
        this.authPopup.removeAttribute('hidden')
        this.restoreScroll = preventScroll()
        this.untrapFocus = trapFocus(this.authPopup, this.hideFastregPopup.bind(this))
    },
}

export default () => {
    // todo: use deadline in ISO format once it's possible
    const nowDate = new Date()
    const now = Math.round(nowDate.getTime() / 1000)
    const asap = now + 7 * 3600
    const asapDate = new Date(asap * 1000)
    const secondsInDay = 86400

    const validPredefinedDeadlines = {
        today: asap,
        tomorrow: now + secondsInDay,
        '3days': now + 3 * secondsInDay,
        week: now + 7 * secondsInDay,
        month: now + 30 * secondsInDay
    }

    const canOrderToday = asapDate.getDate() === nowDate.getDate()

    const ctaForm = document.getElementById('cta-form')
    if (!ctaForm) return
    
    const ctaButton = document.getElementById('cta-button')
    const deadlineSelect = ctaForm.querySelector('[name="deadline"]')
    const titleField = ctaForm.querySelector('[name="title"]')
    const subjectField = ctaForm.querySelector('[name="type_id"]')

    const selectOptions = deadlineSelect.querySelectorAll('option')
    const todayOption = deadlineSelect.querySelector('[data-deadline="today"]')
    const regUrlField = ctaForm.querySelector('[name="reg_url"]')

    if (!canOrderToday) todayOption.remove()

    selectOptions.forEach(option => {
        const readableDate = option.dataset.deadline
        if (!readableDate) return
        option.value = validPredefinedDeadlines[readableDate]
    })

    regUrlField.value = location.host

    fastregPopup.initPopup()

    ctaButton.addEventListener('click', (e) => {
        e.preventDefault()
        if (
            titleField.reportValidity() &&
            deadlineSelect.reportValidity() &&
            subjectField.reportValidity()
        ) fastregPopup.showFastregPopup()
    })
}

